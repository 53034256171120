import './App.css';
import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import "@stripe/stripe-js";
import { CartProvider } from "use-shopping-cart";

// pages
import Navbar from './components/header/Header';
import Location from './components/pages/Locations';
import Landing from "./components/pages/Home";
import Shop from './components/pages/Shop'; 
import Cancel from "./components/pages/Cancel";
import Success from "./components/pages/Success";
import Footer from "./components/footer/Footer";

function App() {
  return (
    <div className="App">
      <CartProvider
        mode="payment"
        cartMode="client-only"
        stripe={process.env.REACT_APP_STRIPE_KEY}
        successUrl="http://everestindustriesllc.com/success"
        cancelUrl="http://everestindustriesllc.com/cancel"
        currency="USD"
        allowedCountries={["US"]}
        billingAddressCollection={true}
      >
        <Navbar />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/Home" element={<Landing />} />
            <Route path="/Locations" element={<Location />} />
            <Route path="/Shop" element={<Shop />} />
            <Route path="/success" element={<Success />} />
            <Route path="/cancel" element={<Cancel />} />
          </Routes>
        </BrowserRouter>
        <Footer />
      </CartProvider>
    </div>
  );
}

export default App;
