import { Typography } from '@mui/material';
import Carousel from "react-bootstrap/Carousel";
import logo from "../../images/Logo.png";
import Divider from "@mui/material/Divider";
import React from "react";

export default function Landing() {

        return (
        <div>
            <Carousel
              fade
              variant="dark"
              interval="4000"
              controls={false}
              indicators={false}
              id="main-info"
            >
              <Carousel.Item>
                <img className="d-block w-100" src={logo} alt="First slide" />
                <Carousel.Caption>
                  <p>We deliver high quality mobile accessories.</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src={logo} alt="Second slide" />

                <Carousel.Caption>
                  <p>
                    Everest Industries LLC is located in the USA, China, and
                    Nepal.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src={logo} alt="Third slide" />

                <Carousel.Caption>
                  <p>
                    Click the menu button at the top left to see more information.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>

            <Divider style={{ margin: "24px auto", width: 60 }} />

            <Typography align={"center"} gutterBottom color={"textSecondary"}>
              This is dummy text, the website is currently under construction. More information to come.
            </Typography>
          </div>
        );
    }
