import * as React from 'react';
import { Typography } from '@mui/material';
import { useState } from 'react';
import CardIcon from "../../images/credit-card.svg";
import ProductImage from "../../images/iphone-c-cable.jpg";
import CarMountImage from "../../images/carMountHolder.jpg";
import UsbAToUsbC2M from "../../images/USB-A-USB-C-2M.png";
import UsbCToLightning1M from "../../images/USB-A-LIGHTNING-1M.png";
import UsbCToLightning2M from "../../images/iphone-c-cable-2m.png";
import Grid from "@mui/material/Grid";

import Card from "react-bootstrap/Card";
//importing payment tool
import { loadStripe } from "@stripe/stripe-js";
import { isMobile } from "react-device-detect";
let stripePromise;
let deviceType;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  }
  return stripePromise;
};

function getDeviceType () {
  if(isMobile){
    deviceType = false;
  } else {
    deviceType = true;
  }
}

const Checkout = () => {
  const [stripeError, setStripeError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const items = [
    {
      id: "price_1KiJxCKTK3u96pLWUNAOvodV",
      price: 5.3,
      description: "an iPhone C to Lightning charger",
      currency: "USD",
      quantity: 1,
      image: ProductImage,
      name: "iPhone C to Lightning",
    },
    {
      id: "price_1KiJxwKTK3u96pLWX5BBo8V0",
      quantity: 1,
      price: 11.0,
      image: CarMountImage,
      name: "Magnetic Phone Mount Holder",
    },
    {
      id: "price_1KiK77KTK3u96pLWTikOOO2x",
      quantity: 1,
      price: 6.0,
      image: UsbCToLightning2M,
      name: "USB C to Lightning (2M)",
    },
    {
      id: "price_1KiKFGKTK3u96pLW9nb1mwnp",
      quantity: 1,
      price: 4.50,
      image: UsbAToUsbC2M,
      name: "2.0 USB-A to USB-C Charging Cable",
    },
    {
      id: "price_1KiKK4KTK3u96pLWCwZrRAor",
      quantity: 1,
      price: 3.20,
      image: UsbCToLightning1M,
      name: "USB to Lightning (1M)",
    },
  ];

   function getStripeItemConfig (itemID) {
    const item = {
      lineItems: [
        { price: items[itemID].id, quantity: items[itemID].quantity },
      ],
      mode: "payment",
      successUrl: "http://localhost:3000/success",
      cancelUrl: "http://localhost:3000/cancel",
    };

    return item;
  }
 
 
  // const checkoutOptions = {
  //   lineItems: [items],
  //   mode: "payment",
  //   successUrl: "http://localhost:3000/success",
  //   cancelUrl: "http://localhost:3000/cancel"
  // };


  const redirectToCheckout = async (itemIndex) => {
    setLoading(true);
    console.log("redirectToCheckout");
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout(getStripeItemConfig(itemIndex));
    console.log("Stripe checkout error", error);

    if (error) setStripeError(error.message);
    setLoading(false);
  };

  if (stripeError) alert(stripeError);
  getDeviceType();
    return (
      <div className="checkout-container">
        <center>
          <Grid
            container={deviceType}
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            {items.map((item, index) => (
              <Card
                style={{ width: "18rem", margin: "1rem", flex: 1 }}
                key={index}
              >
                <Card.Body>
                  <Card.Title className="checkout-title">
                    {item.name}
                  </Card.Title>
                  <Card.Img
                    variant="top"
                    className="checkout-product-image"
                    src={item.image}
                    alt="Product"
                  />
                  <Card.Text className="checkout-description">
                    ${item.price} | {item.name}
                  </Card.Text>
                  <button
                    className="checkout-button"
                    onClick={() => redirectToCheckout(index)}
                    disabled={isLoading}
                  >
                    <div className="grey-circle">
                      <div className="purple-circle">
                        <img
                          className="icon"
                          src={CardIcon}
                          alt="credit-card-icon"
                        />
                      </div>
                    </div>
                    <div className="text-container">
                      <p className="text">
                        {isLoading ? "Loading..." : "Buy Now"}
                      </p>
                    </div>
                  </button>
                </Card.Body>
                <br></br>
              </Card>
            ))}
          </Grid>
        </center>
        <br></br>
      </div>
    );
  }
export default Checkout;


