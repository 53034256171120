import * as React from 'react';
import { Typography } from '@mui/material';
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';

const location = [
  {
    title: "USA Office",
    subheader:"Schaumburg, IL",
    description: [
      "513 S. Salem Dr, Schaumburg IL 60193"
    ],
    buttonText:"8474667639",
    buttonVariant: "contained"
  },
  {
    title: "Hong Kong Office",
    subheader: "XIANG XUAN IMPORT AND EXPORT CO. LIMTIED",
    description: [
      "FLAT A, 12/F, MW TOWER I, 5 KIMBERLEY STREET, \nTSIM SHA TSUI, KLN HONG KONG",
    ],
    buttonText: "0086-15859593799",
    buttonVariant: "contained",
  },
  {
    title: "Nepal Office",
    subheader: "MIRAGE INTERNATIONAL",
    description: [
      "GONGABU, LAXMI NAGAR, OPPOSITE TO NEW BUS PARK, \nKATHMANDU, NEPAL"
    ],
    buttonText: "00977-9860582933",
    buttonVariant: "contained",
  },
  {
    title: "China Office",
    subheader:"QUANZHOU SUN SHINE IMPORT AND EXPORT CO. LTD",
    description: [
      "BAI HONG YUJING TIANXIA, ROOM NO: 1311, BUILDING NO: 20, CENTURY AVENUE \nNUMBER 988 MEILING STREET, JINJIAN CITY< FUJIAN PROVINCE, CHINA"
    ],
    buttonText: "0086-13615940083",
    buttonVariant: "contained",
  },
];


class Location extends React.Component {
    render() {
        return (
          <div class="location" styles="display: inline-block">
            <Typography
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
              align={"center"}
              marginTop={"5%"}
            >
              Locations
            </Typography>

            <Divider
              style={{ margin: "24px auto", width: 60, color: "black" }}
            />

            <Container maxWidth="md" component="main">
              <Grid container spacing={2} alignItems="flex-end">
                {location.map((location) => (
                  // Enterprise card is full width at sm breakpoint
                  <Grid
                    item
                    key={location.title}
                    xs={12}
                    sm={location.title === "Enterprise" ? 12 : 6}
                    md={12}
                  >
                    <Card>
                      <CardHeader
                        title={location.title}
                        subheader={location.subheader}
                        titleTypographyProps={{ align: "center" }}
                        subheaderTypographyProps={{
                          align: "center",
                        }}
                        sx={{
                          color: "white",
                          backgroundColor: (theme) =>
                            theme.palette.mode === "light"
                              ? theme.palette.primary.main
                              : theme.palette.primary.main,
                        }}
                      />
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "baseline",
                            mb: 2,
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              color: "white",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "baseline",
                              mb: 1,
                            }}
                          >
                            {location.officeName}
                          </Typography>
                        </Box>

                        {location.description.map((line) => (
                          <Typography
                            variant="subtitle1"
                            align="center"
                            key={line}
                          >
                            {line}
                          </Typography>
                        ))}
                      </CardContent>
                      <CardActions>
                        <Button fullWidth variant={location.buttonVariant} href={'tel:' + location.buttonText}>
                          {location.buttonText} 
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </div>
        );
    }
} 
export default Location;