import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";

const Footer = () => {
  return (
    <div id="footer-container">
      <div
        id="footer"
        style={{ maxWidth: "100%", margin: "auto", textAlign: "center" }}
      >
        <Typography variant="caption" align={"center"} color={"white"}>
          © Copyright 2022
        </Typography>
        <Divider style={{ margin: "24px auto", width: 60, color: "white" }} />
        <Grid container justify={"center"} spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Typography align={"center"} gutterBottom color={"white"}>
              About
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Link href="/shop">
              <Typography align={"center"} gutterBottom color={"white"}>
                Products
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Link href="/locations">
              <Typography align={"center"} gutterBottom color={"white"}>
                Locations
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography align={"center"} gutterBottom color={"white"}>
              Contact us
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

Footer.propTypes = {};
Footer.defaultProps = {};

export default Footer;
