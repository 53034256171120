import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import "@stripe/stripe-js";
// pages
import Navbar from './components/header/Header';
import Location from './components/pages/Locations';
import Landing from "./components/pages/Home";
import Shop from './components/pages/Shop'; 
import Cancel from "./components/pages/Cancel";
import Success from "./components/pages/Success";
import Footer from "./components/footer/Footer";
ReactDOM.render(
  <React.StrictMode>
    <Navbar />
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/Home" element={<Landing />} />
        <Route path="/Locations" element={<Location />} />
        <Route path="/Shop" element={<Shop />} />
        <Route path="/success" element={<Success />} />
        <Route path="/cancel" element={<Cancel />} />
      </Routes>
    </BrowserRouter>
    <Footer />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
